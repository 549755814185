import React, { createContext, useContext, useState } from 'react';

const SnackbarContext = createContext();

// Snackbar hooks are used to show a message to the user depending on the action they perform.

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const openSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar, snackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
};