import React from "react";
import {IconButton} from "@mui/material";
import CustomTooltip from "../customComponents/CustomTooltip";
import {color} from "../../theme";
import Typography from "@mui/material/Typography";
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

const CardImage = ({src, alt, onPreview, hoverText, showIcon, isStartFromScratch}) => {
    return (
        <div style={{position: 'relative', lineHeight: '0', overflow: 'hidden', width: '100%', height: '70%'}}>
            <img
                src={src}
                alt={alt}
                loading="lazy"
                style={{
                    width: '100%',
                    display: 'block',
                    height: isStartFromScratch ? '100%' : 'auto',
                }}
                onClick={onPreview}
            />
            <CustomTooltip title={hoverText}>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'background-color 0.3s ease-in-out',
                        cursor: 'pointer',
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = color.black.black30;
                        e.currentTarget.childNodes.forEach(child => child.style.opacity = 1);
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.childNodes.forEach(child => child.style.opacity = 0);
                    }}
                    onClick={onPreview}
                >
                    {showIcon && (
                        <IconButton
                            style={{
                                opacity: 0,
                                color: 'white',
                                transition: 'opacity 0.3s ease-in-out',
                                backgroundColor: 'transparent',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                            aria-label="forhåndsvisning"
                        >
                            <Typography variant="body1">Forhåndsvisning</Typography><EastOutlinedIcon/>
                        </IconButton>
                    )}
                </div>
            </CustomTooltip>
        </div>
    );
};

export default CardImage;
