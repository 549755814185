import React from 'react';
import {Tooltip} from '@mui/material';
import {styled} from '@mui/system';
import {typography} from '../../theme';

const CustomTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))({
    [`& .MuiTooltip-tooltip`]: {
        fontFamily: 'Poppins',
        fontSize: typography.caption.fontSize
    },
});

export default CustomTooltip;