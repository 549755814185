import axiosInstance from "../authentication/axiosInstance";

// Function to refresh Google credentials
const refreshGoogleCredentials = async (refreshToken) => {
    try {
        // Replace with the correct URL and ensure this endpoint is set up in your backend to accept the refresh token and return new credentials
        const response = await axiosInstance.post('/refresh-access-token/', {
            google_refresh_token: refreshToken,
        });

        // Handle response with the new access token
        if (response.status === 200 && response.data.google_access_token) {
            localStorage.setItem('jwtToken', response.data.token);
            localStorage.setItem('google_access_token', response.data.google_access_token);
            localStorage.setItem('google_refresh_token', response.data.google_refresh_token);
            return {
                success: true,
                accessToken: response.data.google_access_token,
                jwtToken: response.data.token,
            };
        } else {
            // Handle any other cases, such as missing data or other statuses
            return {success: false, message: 'Failed to refresh Google credentials.'};
        }
    } catch (error) {
        console.error('Error refreshing Google credentials:', error);
        // You might want to handle the error state here, such as redirecting to a login page or showing an error message
        return {success: false, message: error};
    }
};

export default refreshGoogleCredentials;