import React from 'react';
import {Box, Card, CardActions, CardContent, Skeleton} from '@mui/material';

const SkeletonMyWebsiteCard = ({isCompact}) => {
    const cardWidth = isCompact ? '450px' : '500px';
    const cardHeight = isCompact ? 'auto' : 'fit-content';

    return (
        <Card
            sx={{
                display: 'flex',
                width: cardWidth,
                padding: '2em',
                height: cardHeight,
                flexDirection: 'column',
                mr: '3em',
                boxShadow: 'none',
                border: '1px solid #e0e0e0',
            }}
        >
            <CardContent
                sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                <Skeleton variant="text" width="60%" height={60} sx={{mb: 3}}/>
                <Skeleton variant="text" width="80%" height={40} sx={{mb: 2}}/>
            </CardContent>
            <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <Skeleton variant="rectangular" width="100%" height={250} sx={{mb: 2}}/>
                <CardActions sx={{mt: 'auto', justifyContent: 'center'}}>
                    <Skeleton variant="rectangular" width="100%" height={40}/>
                </CardActions>
            </Box>
        </Card>
    );
};

export default SkeletonMyWebsiteCard;
