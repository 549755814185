import axiosInstance from "../authentication/axiosInstance";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);  // Replace with your Stripe publishable key

const createEkstraNettsideCheckoutSession = async () => {
    try {
        const response = await axiosInstance.post('/create-checkout-extra-website/');
        const {id} = response.data;

        const stripe = await stripePromise;
        const {error} = await stripe.redirectToCheckout({sessionId: id});

        if (error) {
            console.error('Error redirecting to Stripe Checkout:', error);
        }
    } catch (error) {
        console.error('Error creating Stripe Checkout Session:', error);
    }
};

export default createEkstraNettsideCheckoutSession