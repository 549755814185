import axiosInstance from '../authentication/axiosInstance';

const detachCustomDomain = async (projectId, customDomain) => {
    const response = await axiosInstance.delete('/detach-custom-domain/', {
        data: {project_id: projectId, custom_domain: customDomain},
    });
    return response;
};

export default detachCustomDomain;
