import React from 'react';
import {Box, Skeleton} from '@mui/material';

const SkeletonTemplateSubpage = () => {
    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} my={3}
                 sx={{ml: '132px', mb: 5}}>
                <Box sx={{display: 'flex', marginLeft: '2.1em'}}>
                    <Skeleton variant="text" width={150} height={40} sx={{mr: 2}}/>
                    <Skeleton variant="rectangular" width={100} height={40}/>
                </Box>
                <Skeleton variant="rectangular" width={300} height={40} sx={{mr: '2.1em'}}/>
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap='12px' alignItems="center" marginLeft="132px"
                 justifyContent="space-evenly">
                {[...Array(6)].map((_, index) => (
                    <Box key={index} sx={{width: '450px', height: '578px', mb: 4, p: 0}}>
                        <Skeleton variant="rectangular" width="100%" height="70%" sx={{mb: 2}}/>
                        <Skeleton variant="text" width="80%" height={40} sx={{mb: 2}}/>
                        <Skeleton variant="rectangular" width="90%" height={40}/>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default SkeletonTemplateSubpage;
