import React, {useEffect} from 'react';
import {Box, Card, CardContent, Container, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Navbar from '../components/navbar/Navbar';
import {color} from '../theme';
import {CustomButton} from "../components/customComponents/CustomButton";
import {useNavigate} from "react-router-dom";
import {fetchSubscriptionStatus} from "../helperFunctions/stripe/fetchSubscriptionStatus";

const PaymentSuccessPage = () => {
    const navigate = useNavigate();
    let subscriptionType = 'free';

    useEffect(() => {
        const getSubscriptionStatus = async () => {
            const subscriptionData = await fetchSubscriptionStatus();
            let subscriptionType = subscriptionData.subscription_type;
            localStorage.setItem('subscription_status', subscriptionData.status);
            localStorage.setItem('subscription_type', subscriptionType);
            let accountType = 'Gratis-konto';
            if (subscriptionData.status === 'trialing') {
                accountType = 'Trial-konto';
            } else if (subscriptionData.status === 'active') {
                accountType = 'Abonnert';
            }
            localStorage.setItem('account_type', accountType);
        }
        getSubscriptionStatus();
    }, []);

    return (
        <Container sx={{display: 'flex', flexDirection: 'row', height: '100vh'}} maxWidth="lg">
            <Navbar/>
            <Box sx={{
                marginLeft: '208px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}>
                <Card sx={{minWidth: 275, padding: '2em'}}>
                    <CardContent sx={{textAlign: 'center'}}>
                        <CheckCircleIcon sx={{fontSize: 80, color: color.green.baseGreen100, marginBottom: '0.2em'}}/>
                        <Typography variant="h4" component="div" gutterBottom>
                            Betaling mottatt
                        </Typography>
                        <Typography variant="body" sx={{marginTop: '0.5em'}}>
                            Betalingen din var vellykket, og du er nå abonnert
                            {subscriptionType === 'full' ? (
                                <>med <strong>Nettside + Guider.</strong></>
                            ) : (
                                <>med <strong>Nettsideplan.</strong></>
                            )}
                        </Typography>
                        <Typography variant="body" display="block" sx={{marginTop: '0.5em'}}>
                            Du kan nå bruke nettsidebyggeren
                            {subscriptionType === 'full' ? (
                                <>, se guide-siden og publisere (lansere live) nettsidene dine.</>
                            ) : (
                                <> og publisere (lansere live) nettsidene dine.</>
                            )}
                        </Typography>
                        <Box sx={{marginTop: '2em'}}>
                            <CustomButton variant="filled" onClick={() => navigate("/")}>Gå tilbake til
                                dashboard</CustomButton>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default PaymentSuccessPage;
