import axiosInstance from '../authentication/axiosInstance';
const fetchSavedDomain = async (projectId) => {
    try {
        const response = await axiosInstance.get(`/get-saved-domain/?project_id=${projectId}`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch saved domain');
    }
};

export default fetchSavedDomain;