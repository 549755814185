import createSSOLink from "./createSsoLink";
import inviteUserToWorkspace from "./inviteUserToWorkspace";
import createUser from "./createUser";
import createProject from "./createProject";
import createWorkspace from "./createWorkspace";
import getProjectUid from "./getProjectUid";

const handleFormSubmit = async (e, projectId, formData, setSsoLink, setIsSubmitting, setErrorMessage, instantModal, sendEmail) => {
    e.preventDefault();
    setIsSubmitting(true);  // Start loading
    try {
        // Create or retrieve a workspace
        let workspaceId = localStorage.getItem('workspace_id');
        if (!workspaceId) {
            const workspaceResponse = await createWorkspace();
            workspaceId = workspaceResponse.id;
            localStorage.setItem('workspace_id', workspaceId);
        }

        try {
            // Duplicate the project with the correct template
            const projectResponse = await createProject(projectId, formData.name);
            const duplicatedProjectId = projectResponse.duplicated_project_id;

            try {
                // Create or retrieve a Brizy user
                let brizyUserId = localStorage.getItem('brizy_user_id');
                if (!brizyUserId) {
                    const userResponse = await createUser();
                    brizyUserId = userResponse.id;
                    localStorage.setItem('brizy_user_id', brizyUserId);
                }

                try {
                    // Invite user to workspace and approve the invitation if not already invited
                    const inviteResponse = await inviteUserToWorkspace();

                    try {
                        // Retrieve the project UID
                        const projectUid = await getProjectUid(duplicatedProjectId);

                        // Generate the SSO link
                        const ssoResponse = await createSSOLink(projectUid, sendEmail);
                        setSsoLink(ssoResponse.sso_link);
                        setErrorMessage('');
                        setTimeout(() => {
                            window.location.href = ssoResponse.sso_link; // Redirect to the SSO link
                        }, 10000);
                    } catch (ssoError) {
                        setErrorMessage('Kunne ikke opprette SSO-lenken. Prøv igjen eller kontakt support.');
                    }

                } catch (inviteError) {
                    setErrorMessage('Det oppstod en feil under invitasjonen til arbeidsområdet.');
                }

            } catch (userError) {
                setErrorMessage('Det oppstod en feil under opprettelsen av brukeren.');
            }
        } catch (projectError) {
            setErrorMessage('Det oppstod en feil under opprettelsen av prosjektet.');
        }
    } catch (workspaceError) {
        setErrorMessage('Det oppstod en feil under opprettelsen av arbeidsområdet.');
    }
    setIsSubmitting(false);  // End loading
};

export default handleFormSubmit;
