import React from 'react';
import {Box} from '@mui/material';
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import SkeletonMyWebsiteCard from "./SkeletonMyWebsiteCard";

const SkeletonMyWebsitesSubpage = () => {
    const isSmallScreen = useMediaQuery('(max-width:1440px)');
    const containerWidth = isSmallScreen ? '90%' : '90%'; // Adjust container width based on screen size
    return (
        <Container
            disableGutters
            maxWidth={false}
            sx={{marginTop: 8, width: containerWidth, marginLeft: isSmallScreen ? '15%' : '12%'}}>
            <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center"
                 justifyContent="flex-start">
                <SkeletonMyWebsiteCard/>
                <SkeletonMyWebsiteCard/>
            </Box>
        </Container>
    );
};

export default SkeletonMyWebsitesSubpage;
