import {createTheme} from '@mui/material/styles';
import "./colors.css"

const theme = createTheme({
    palette: {
        darkBlue: {
            baseBlue100: 'var(--base-blue-cetacean-100)',
        },

        lightBlue: {
            baseBlue100: 'var(--base-blue-smalt-100)',
            baseBlue25: 'var(--base-blue-smalt-25)',
            blue1: 'var(--blue-smalt-1)',
        },

        green: {
            baseGreen100: 'var(--base-green-100)',
            baseGreen25: 'var(--base-green-25)',
            green1: 'var(--green-1)',
        },

        orange: {
            baseOrange100: 'var(--base-orange-100)',
            baseOrange25: 'var(--base-orange-25)',
            orange1: 'var(--orange-1)',
        },

        white: {
            baseWhite100: 'var(--base-white-100)',
            white100: 'var(--white-100)',
            white30: 'var(--white-30)',
            white10: 'var(--white-10)',
        },

        lightGrey: {
            lightGrey100S1: 'var(--light-grey1-100)',
            lightGrey100S2: 'var(--light-grey2-100)',
            lightGrey25S2: 'var(--light-grey2-25)',
            lightGrey100S3: 'var(--light-grey3-100)',
        },

        darkGrey: {
            darkGrey100S1: 'var(--dark-grey1-100)',
            darkGrey100S2: 'var(--dark-grey2-100)'
        },

        black: {
            black100: 'var(--black-100)',
            black30: 'var(--black-30)',
            black10: 'var(--black-10)'
        },

        beige: {
            beige100: 'var(--beige-100)'
        }
    },

    boxShadow: {
        card: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    },

    typography: {
        h1: {
            fontSize: "3.05em",
            fontWeight: "bold",
            fontFamily: "Fraunces, sans-serif",
        },
        h2: {
            fontSize: "2.44em",
            fontWeight: "bold",
            fontFamily: "Poppins, sans-serif",
        },
        h3: {
            fontSize: "1.95em",
            fontWeight: "bold",
            fontFamily: "Poppins, sans-serif",
        },
        h4: {
            fontSize: "1.56em",
            fontWeight: "bold",
            fontFamily: "Poppins, sans-serif",
        },
        h5: {
            fontSize: "1.25em",
            fontWeight: "bold",
            fontFamily: "Poppins, sans-serif",
        },
        body1: {
            fontSize: "1.25em",
            fontFamily: "Poppins, sans-serif",
        },
        body: {
            fontSize: "1em",
            fontFamily: "Poppins, sans-serif",
        },
        caption: {
            fontSize: "0.8em",
            fontFamily: "Poppins, sans-serif",
        },
        small: {
            fontSize: "0.64em",
            fontFamily: "Poppins, sans-serif",
        },
    },

    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins, sans-serif",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins, sans-serif",
                },
                select: {
                    fontFamily: "Poppins, sans-serif",
                },
                selectLabel: {
                    fontFamily: "Poppins, sans-serif",
                },
                displayedRows: {
                    fontFamily: "Poppins, sans-serif",
                },
                menuItem: {
                    fontFamily: "Poppins, sans-serif",
                },
            },
        },
    },
});

const color = theme.palette;
const typography = theme.typography;

const boxShadow = theme.boxShadow;

export {color, typography, boxShadow};
export default theme;