import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Global styles
import './App.css'; // Component-specific styles
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@mui/material/styles';
import theme from './theme'
import TagManager from "react-gtm-module/dist/TagManager";

// Initialize GTM for login.groweb.no
const loginGrowebTagManagerArgs = {
    gtmId: 'GTM-NJ2CDN3W'
};
TagManager.initialize(loginGrowebTagManagerArgs);

// Initialize GTM for groweb.no
const growebTagManagerArgs = {
    gtmId: 'GTM-PW5Q8JFN'
};
TagManager.initialize(growebTagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
