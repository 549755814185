import React from 'react';
import {Box, Typography} from '@mui/material';
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {color} from "../theme";
import modalStyle from '../components/customComponents/modalStyle'
import PlanOptions from "../components/stripe/PlanOptions";

const RestartSubscriptionPage = () => {
    return (
        <Box sx={{
            ...modalStyle, maxWidth: '60vw',
            width: 'max-content', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '32px 0px'
        }}>
            <InfoIcon sx={{fontSize: '2.5rem', color: color.lightBlue.baseBlue100}}/>
            <Typography variant="h5" sx={{mb: 1, textAlign: 'center'}}>
                Ditt Groweb abonnement har utløpt.
            </Typography>
            <Typography variant="body" sx={{mb: 2, textAlign: 'center'}}>
                Forny din Groweb-abonnement for å få tilgang til Nettside funksjonen og bruke nettsidebyggeren.
            </Typography>
            <PlanOptions trial={false}/>
        </Box>
    );
};

export default RestartSubscriptionPage;
