import axiosInstance from "../authentication/axiosInstance";

export const fetchTemplateCategories = async () => {
    try {
        const response = await axiosInstance.get('/template-categories/');
        return response.data;
    } catch (err) {
        console.error(err);
        return []; // Return an empty array or appropriate error handling
    }
};

export default fetchTemplateCategories;