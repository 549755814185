// StripeButton.js
import React from 'react';
import {CustomButton} from "../customComponents/CustomButton";
import axiosInstance from "../../helperFunctions/authentication/axiosInstance";
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeButton = ({productType = "full", trial = false, text}) => {
    if (!text) {
        text = trial ? "Start prøveperioden din" : "Start Groweb Abonnement";
    }

    const handleClick = async () => {
        try {
            const response = await axiosInstance.post('/create-checkout-session/', {"product_type": productType});
            const {id} = response.data;

            const stripe = await stripePromise;
            const {error} = await stripe.redirectToCheckout({sessionId: id});

            if (error) {
                console.error('Error redirecting to Stripe Checkout:', error);
            }
        } catch (error) {
            console.error('Error creating Stripe Checkout Session:', error);
        }
    };

    return (
        <CustomButton colors="quaternary" onClick={handleClick}>
            {text}
        </CustomButton>
    );
};

export default StripeButton;