import {useAuth} from "../../helperFunctions/authentication/authContext";
import {Navigate} from "react-router-dom";

const PrivateRoute = ({children}) => {
    const {isAuthenticated} = useAuth();

    if (!isAuthenticated) {
        // Redirect to login
        return <Navigate to="/login" replace/>;
    }

    return children;
};

export default PrivateRoute;