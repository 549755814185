import {styled} from '@mui/system';
import {TextField} from '@mui/material';
import {color} from '../../theme';

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: color.lightGrey.lightGrey100S1,
        },
        '&:hover fieldset': {
            borderColor: color.lightGrey.lightGrey100S2,
        },
        '&.Mui-focused fieldset': {
            borderColor: color.lightBlue.baseBlue100,
        },
        '& input': {
            fontFamily: 'Poppins, sans-serif',
        },
        '& input::placeholder': {
            fontFamily: 'Poppins, sans-serif',
        },
    },
    '& .MuiInputLabel-root': {
        fontFamily: 'Poppins, sans-serif',
    },
    '& .MuiFormHelperText-root': {
        fontFamily: 'Poppins, sans-serif',
    },
});

export {CustomTextField};
