// templateTranslations.js
const templateTranslations = {
    "Snow Removal": "Snøfjerning",
    "PC Repair": "PC Reparasjon",
    "Landscaping": "Hagearbeid",
    "Handymen": "Håndverkere",
    "Driving": "Kjøring",
    "Dog Walking": "Hundelufting",
    "Daycare": "Barnehage",
    "Carpentry": "Snekkerarbeid",
    "BodyCar": "Bilpleie",
    "Babysitting": "Barnevakt",
    "Appliance": "Hvitevarer",
    "Animal Shelter": "Dyrehjem",
    "Veterinary": "Veterinær",
    "Workshop": "Coaching",
    "Parley": "Podcast",
    "Halbor": "Havn",
    "Gym Trainer": "Personlig trener",
    "Tavern": "Restaurant",
    "Nestnet": "Abonnementssalg",
    "Gprig": "SaaS Landingssider",
    "Portfolio": "Portefølje",
    "Petgo": "Dyrebutikk",
    "Cleanoz": "Rengjøring",
    "Music Tutor": "Musikklærer",
    "Podkast": "Podcast",
    "Nursery": "Barnehage",
    "Dance Tutor": "Danselærer",
    "Dojo Sensei": "Kampsport",
    "Linoor": "Linoor",
    "Growth": "Vekst",
    "Yoga 2": "Yoga",
    "Trefon": "Trefon",
    "Travely": "Reise",
    "Telemedicine": "Telemedisin",
    "Tattoo shop": "Tatoveringssjappe",
    "Sushi": "Sushi",
    "Starter": "Start",
    "Skiing": "Ski",
    "Sintix": "Sintix",
    "Scooter Rental": "Scooterutleie",
    "Safari": "Safari",
    "Restaurant": "Restaurant",
    "Real Talk": "Real Talk",
    "Rana Foxa": "Rana Foxa",
    "Podact": "Podact",
    "Pizza": "Pizza",
    "Parfume": "Parfyme",
    "Orange": "Fotograf",
    "Oktane Crypto": "Kryptovaluta",
    "Number 5": "Nummer 5",
    "Mimo Design": "Mimo Design",
    "Mechanics": "Mekanikere",
    "IT Service": "IT Service",
    "Insurace": "Forsikring",
    "Horses": "Hester",
    "Home Shop": "Hjemmebutikk",
    "Healthy": "Sunnhet",
    "Gym": "Gym",
    "Fashion Models": "Modellbyrå",
    "Electric": "Elektrisk",
    "Eladio": "Eladio",
    "E-Cycle": "El-sykler",
    "Dietitian": "Ernæringsfysiolog",
    "Dance School": "Danseskole",
    "Crypto Things": "Krypto",
    "Corpio": "Corpio",
    "Car Wash": "Bilvask",
    "Car Parts": "Bildeler",
    "Brandio": "Brandio",
    "Birthday": "Bursdag",
    "Wedding": "Bryllup",
    "Co-Space": "Samarbeidsområde",
    "Drone X": "Drone",
    "Smart Learning": "Læring",
    "Surf School": "Surfeskole",
    "Football United": "Fotballforening",
    "Memento App": "Memento App",
    "Craft Wise": "Håndverk",
    "Coin Finance": "Finans",
    "Design Loft": "Interiør",
    "Empire SaaS": "SaaS",
    "Camera App": "App",
    "Ranking SEO": "SEO Byrå",
    "Gourmet Food": "Gourmetmat",
    "Molino Bake": "Molino Bake",
    "Architect": "Arkitekt",
    "Fix It": "Reparere",
    "Wine Vine": "Vin",
    "Moon Band": "Moon Band",
    "Workspaces": "Kontor",
    "Pet Vet Care": "Veterinær",
    "Pearl Dental": "Perle Tannpleie",
    "Natural Beauty Spa": "Spa",
    "IT Group": "IT Gruppe",
    "Kids": "Barn",
    "Private School": "Privatskole",
    "Carpenter": "Snekker",
    "Manicure": "Manikyr",
    "Hairdresser": "Frisør",
    "Minimal": "Minimal",
    "Wedding Photo": "Bryllupsfotograf",
    "Zoo": "Zoo",
    "Interior Design": "Interiørdesign",
    "Politics 2": "Politikk 2",
    "Medical Center": "Helsestasjon",
    "Call Center": "Call Center",
    "Consultant": "Konsulent",
    "CryptoBold": "CryptoBold",
    "Party New": "Event",
    "Spa Center": "Spa Senter",
    "Finance": "Finans",
    "Party 1": "Fest Event",
    "Tailor 2": "Skredder 2",
    "Music Site": "Musikk",
    "Catering": "Catering",
    "Resto": "Resto",
    "Laboratory": "Laboratorium",
    "Garden": "Hage",
    "Swimming Pool": "Svømmebasseng",
    "Architect 2": "Arkitekt 2",
    "Taxi": "Taxi",
    "Payss": "Payss",
    "Adventure": "Eventyr",
    "The Farm": "Gård",
    "Festival": "Festival",
    "Photography Course": "Fotokurs",
    "Meals on Wheels": "Food truck",
    "Spirit": "Spirit",
    "SEO Agency": "SEO Byrå",
    "Codesk": "Codesk",
    "Lodiz": "Lodiz",
    "Oapee": "Oapee",
    "Booky": "Bok",
    "Ditox": "Ditox",
    "Flooring": "Gulvlegging",
    "Copyrits": "Copyrits",
    "Marketr": "Marketr",
    "Solting": "Solting",
    "Vopril": "Vopril",
    "Soofer": "Soofer",
    "Trainer": "Instruktør",
    "Fitness": "Fitness",
    "Houziz": "Houziz",
    "Webno": "Webno Digital",
    "Bizgo": "Bizgo",
    "Zarbin": "Zarbin",
    "Okloos": "Optiker",
    "Timon": "Timon",
    "Course": "Kurs",
    "eBook": "eBook",
    "Riston": "Restaurant",
    "Medico": "Lege",
    "Motion": "Motion",
    "Guide": "Guide",
    "Dieting": "Dieting",
    "Atorno": "Atorno",
    "Instom": "Instagram",
    "Beauty": "Skjønnhet",
    "Black Friday": "Black Friday",
    "Ekstra": "Ekstra",
    "Appside": "Appside",
    "Moves": "Moves",
    "Hitched": "Hitched",
    "Summit": "Summit",
    "Digital Raw": "Digital Raw",
    "Startapp": "Startapp",
    "Unicorn": "Unicorn",
    "Supreme": "Supreme",
    "Swipe": "Swipe",
    "Resume": "CV",
    "Parlor": "Parlor",
    "Formstyle": "Formstyle",
    "Webinar": "Webinar",
    "In Shape": "Treningssenter",
    "Keynote": "Keynote",
    "Kid Quest": "Kid Quest",
    "Philanthropy": "Filantropi",
    "Quantum": "Quantum",
    "Reel Story": "Reel Story",
    "Skypoint": "Skypoint",
    "Yoga": "Yoga",
    "Beach Resort": "Strandresort",
    "Car Clinic": "Bilklinikk",
    "College": "Høyskole",
    "Creed": "Creed",
    "Base Ground": "Base Ground",
    "Alpine Lodge": "Fjellhytte",
    "Advisors": "Rådgivere",
    "Lavish": "Lavish",
    "Welness": "Velvære",
    "Refix": "Refix",
    "Kaufman": "Kaufman",
    "The Core": "Kjernen",
    "Pathfinder": "Natursti",
    "Kids Play": "Lekeplass",
    "Homefeast": "Hjemmefest",
    "Practice": "Øvelse",
    "Digital Work": "Digitalt Arbeid",
    "Cribs": "Cribs",
    "Spotless": "Spotless",
    "Agens": "Agens",
    "Hope": "Håp",
    "Flavour": "Smak",
    "Avalon": "Avalon",
    "Agrikol": "Agrikol",
    "Azino": "Azino",
    "Bebio": "Bebio",
    "Yachter": "Yachter",
    "Uplyft": "Uplyft",
    "Krowd": "Krowd",
    "Politiko": "Politiko",
    "Brick & Mortar": "Brick & Mortar",
};

export default templateTranslations;
