import axiosInstance from '../authentication/axiosInstance';

const getDnsDetails = async (projectId, customDomain) => {
    const response = await axiosInstance.get('/get-dns-details/', {
        params: {
            project_id: projectId,
            custom_domain: customDomain,
        },
    });
    return response.data;
};

export default getDnsDetails;
