import React from 'react';
import {Alert, Box, CardContent, Menu, Typography} from '@mui/material';
import {CustomButton} from '../customComponents/CustomButton';
import SearchField from "./SearchField";
import CardImage from "./CardImage";
import {CustomCard} from "../customComponents/CustomCard";
import CustomTooltip from "../customComponents/CustomTooltip";
import templatePreviewUrls from "../../helperFunctions/nettside/templatePreviewUrls";
import {CustomMenuItem} from "../customComponents/CustomMenuItem";
import templateTranslations from "../../helperFunctions/nettside/translations/templateTranslations";
import {KeyboardDoubleArrowDown} from "@mui/icons-material";
import SkeletonCard from "../skeleton/SkeletonCard";

// Translation map with counts
const categoryTranslationsWithCounts = {
    "All": {name: "Alle", count: 213},
    "Layouts": {name: "Enkel", count: 132},
    "Landing": {name: "Landingssider", count: 83},
    "Free": {name: "Populære", count: 9},
    "Business": {name: "Bedrift", count: 126},
    "Education": {name: "Utdanning", count: 27},
    "Food": {name: "Restaurant", count: 22},
    "Art & Music": {name: "Kunst og Musikk", count: 29},
    "Portfolio": {name: "Portefølje", count: 30},
    "Sport & Health": {name: "Sport og Helse", count: 37},
    "Travel": {name: "Reise", count: 18}
};

const TemplateSubpage = ({
                             searchQuery,
                             handleSearchChange,
                             isLoading,
                             errorMessage,
                             displayedTemplates,
                             setSelectedTemplateId,
                             displayCount,
                             handleLoadMore,
                             filteredTemplates,
                             templateCategories = [],
                             selectedCategory,
                             handleCategoryChange
                         }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} my={3}
                 sx={{ml: '132px', mb: 5}}>
                <Box sx={{display: 'flex', marginLeft: '2.1em'}}>
                    <Typography gutterBottom variant='h5' component="div"
                                sx={{alignSelf: 'center', mt: '6px'}}
                    >
                        Filtrer utkast
                    </Typography>
                    <CustomButton
                        aria-controls="category-menu"
                        aria-haspopup="true"
                        onClick={handleOpenMenu}
                        variant={selectedCategory !== 3304262 ? "contained" : "outlined"}
                        colors="sixth"
                        sx={{marginLeft: '16px'}}
                    >
                        Kategorier
                    </CustomButton>
                </Box>
                <Menu
                    id="category-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                >
                    {templateCategories.map(category => {
                        const translation = categoryTranslationsWithCounts[category.title];
                        const categoryName = translation ? translation.name : category.title;
                        const templateCount = translation ? translation.count : 0;
                        return (
                            <CustomMenuItem
                                key={category.id}
                                selected={selectedCategory === category.id}
                                onClick={() => handleCategoryChange(category.id)}
                            >
                                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '200px'}}>
                                    <span>{categoryName}</span>
                                    <span>({templateCount})</span>
                                </Box>
                            </CustomMenuItem>
                        );
                    })}
                </Menu>
                <SearchField searchQuery={searchQuery} handleSearchChange={handleSearchChange} label="Søk etter mal..."
                             marginRight={"2.1em"}/>
            </Box>
            {isLoading ? (
                // Skeleton cards
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap='12px' alignItems="center"
                     marginLeft="132px" justifyContent="space-evenly">
                    {Array.from(new Array(displayCount)).map((_, index) => (
                        <SkeletonCard key={index}/>
                    ))}
                </Box>
            ) : errorMessage ? (
                <Box sx={{ml: '208px', width: '100%'}}>
                    <Alert severity="error">{errorMessage}</Alert>
                </Box>
            ) : (
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap='12px' alignItems="center"
                     marginLeft="132px" justifyContent="space-evenly">
                    {displayedTemplates.map((template, index) => {
                        const previewUrl = templatePreviewUrls[template.title];
                        const translatedTitle = templateTranslations[template.title] || template.title;
                        const title = translatedTitle;
                        const hoverText = "Forhåndsvisning av nettsidemal";

                        return (
                            <CustomCard key={index} size="s" sx={{
                                mb: 4,
                                overflow: 'hidden',
                                padding: '0px',
                                width: '450px',
                                height: '578px',
                            }}>
                                <CardImage
                                    src={template.featured_image}
                                    alt={`Forhåndsvisning av ${translatedTitle}`}
                                    onPreview={() => {
                                        if (previewUrl) {
                                            window.open(previewUrl);
                                        }
                                    }}
                                    hoverText={hoverText}
                                    showIcon={true}
                                    isStartFromScratch={false}
                                />
                                <Typography gutterBottom variant='h5' component="div"
                                            sx={{mb: '1em', ml: '2em', mt: '1.5em', textAlign: 'left'}}>
                                    {title}
                                </Typography>
                                <CardContent sx={{display: 'flex', justifyContent: 'space-evenly'}}>
                                    <CustomTooltip title="Bygg en nettside med denne malen" arrow>
                                        <CustomButton colors="sixth" onClick={() => {
                                            setSelectedTemplateId(template.project);
                                        }}
                                                      sx={{width: '88%'}}
                                        >
                                            Velg utkast
                                        </CustomButton>
                                    </CustomTooltip>
                                </CardContent>
                            </CustomCard>
                        );
                    })}
                    {displayCount < filteredTemplates.length && (
                        <Box display="flex" justifyContent="center" width="100%">
                            <CustomButton
                                aria-label="load-more-button"
                                onClick={handleLoadMore}
                                size={"large"}
                                variant="outlined"
                                colors="sixth"
                                sx={{
                                    mb: '2.5em',
                                    padding: '12px 22px',
                                    transition: 'all 0.3s ease-in-out',
                                    '&:hover': {
                                        background: '#170F49',
                                        color: 'white !important',
                                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                                    }
                                }}
                            >
                                <Typography variant="body" sx={{fontWeight: '600'}}>Flere utkast</Typography>
                                <KeyboardDoubleArrowDown/>
                            </CustomButton>
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

export default TemplateSubpage;
