import {Box, IconButton, Modal, Typography} from "@mui/material";
import modalStyle from "../customComponents/modalStyle";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {color} from "../../theme";
import React from "react";
import PlanOptions from "./PlanOptions";

const PaywallModal = ({
                          openPaywallModal,
                          handleClosePaywallModal,
                          text,
                          trial = false
                      }) => {
    return (
        <Modal
            open={openPaywallModal}
            onClose={handleClosePaywallModal}
            aria-labelledby="paywall-modal-title"
            aria-describedby="paywall-modal-description"
        >
            <Box sx={{
                ...modalStyle,
                maxWidth: '80vw',
                maxHeight: '85vh',
                width: '100%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: {xs: 2, sm: 4},
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 2,
            }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClosePaywallModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <InfoIcon
                    sx={{fontSize: {xs: '1.5rem', sm: '1.75rem', md: '2rem'}, color: color.lightBlue.baseBlue100}}/>
                <Typography variant="body" sx={{
                    mt: 1,
                    textAlign: 'center',
                    fontSize: {xs: '1rem', sm: '1.25rem', md: '1.5rem'}
                }}>
                    {text}
                </Typography>
                <PlanOptions trial={trial}/>
            </Box>
        </Modal>
    )
}

export default PaywallModal;