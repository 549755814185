import axiosInstance from '../authentication/axiosInstance';

const deleteProject = async (projectId) => {
    try {
        const response = await axiosInstance.delete(`/delete-project/${projectId}/`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw error.response.data;
        } else {
            // Log the error for debugging purposes
            console.error('Error during project deletion:', error);
            // Return a custom success message
            return {message: 'Project deletion encountered an internal error, but proceeding as if successful.'};
        }
    }
};

export default deleteProject;
