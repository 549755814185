import axiosInstance from '../authentication/axiosInstance';

const fetchSubdomain = async (projectId) => {
    try {
        const response = await axiosInstance.get(`/get-subdomain/${projectId}/`);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};

export default fetchSubdomain;