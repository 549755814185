import axiosInstance from "../authentication/axiosInstance";

const inviteUserToWorkspace = async () => {
    try {
        // Use axiosInstance to make the post request
        const response = await axiosInstance.post('/invite-user/');

        // Check if the request was successful based on the status code
        if (response.status === 200) {
            // The response data is available in `response.data`
            return response.data; // Return the response data directly
        } else {
            // Handle any other status codes as an error
            console.error('Failed to invite user to workspace:', response.data);
            throw new Error(response.data.error || 'Invitation Failed');
        }
    } catch (error) {
        // Log and rethrow the error for further handling
        console.error('Error inviting user to workspace:', error);
        throw error;
    }
};

export default inviteUserToWorkspace;
