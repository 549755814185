import React from 'react';
import {Box, Skeleton} from '@mui/material';
import {CustomCard} from '../customComponents/CustomCard';

const SkeletonCard = () => {
    return (
        <CustomCard size="s" sx={{
            mb: 4,
            overflow: 'hidden',
            padding: '0px',
            width: '450px',
            height: '578px',
        }}>
            <Skeleton variant="rectangular" width="100%" height="70%"/>
            <Box sx={{p: 2}}>
                <Skeleton variant="text" width="30%" height={36}
                          sx={{mb: '2em', ml: '1.5em', textAlign: 'left'}}/>
                <Skeleton variant="rectangular" width="88%" height={40} sx={{margin: 'auto'}}/>
            </Box>
        </CustomCard>
    );
};

export default SkeletonCard;
