// Loader.js
import React from 'react';
import {Box, CircularProgress} from '@mui/material';

const Loader = ({size}) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress size={size}/>
        </Box>
    );
};

export default Loader;
