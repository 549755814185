const industryTranslations = {
    "Accounting": "Regnskap",
    "Acupuncture": "Akupunktur",
    "Audio Editing": "Lydredigering",
    "Audio Production": "Lydproduksjon",
    "Bakery": "Bakeri",
    "Barber Shop": "Barberbutikk",
    "Beauty": "Skjønnhet",
    "Bike Mechanic": "Sykkelmekaniker",
    "Boat Detailing": "Båtdetaljering",
    "Bookkeeping": "Bokføring",
    "Business Consulting": "Forretningsrådgivning",
    "Cafe": "Kafé",
    "Car Detailing": "Bildetaljering",
    "Car Rentals": "Bilutleie",
    "Career Coaching": "Karriererådgivning",
    "Carpet Cleaning": "Tepperens",
    "Catering": "Catering",
    "Chef": "Kokk",
    "Chiropractor": "Kiropraktor",
    "Church": "Kirke",
    "Cleaning": "Rengjøring",
    "Coaching": "Coaching",
    "Coffee Shop": "Kaffebar",
    "Computer Repair": "Datamaskinreparasjon",
    "Construction": "Bygg",
    "Consulting": "Konsulenttjenester",
    "Content Writing": "Innholdsskriving",
    "Copywriting": "Tekstforfatting",
    "Counselling": "Rådgivning",
    "Dentist": "Tannlege",
    "Design": "Design",
    "Development and Design": "Utvikling og design",
    "Digital Marketing": "Digital markedsføring",
    "DJ": "DJ",
    "Doctor": "Lege",
    "Dog Training": "Hundetrening",
    "Dog Walking": "Hundelufting",
    "Education": "Utdanning",
    "Electric Scooters": "Elektriske scootere",
    "Electric Vehicles": "Elektriske kjøretøy",
    "Electrician": "Elektriker",
    "EV Rentals": "EV utleie",
    "Event Planning": "Eventplanlegging",
    "Fashion": "Mote",
    "Fast Food": "Hurtigmat",
    "Financial Consulting": "Finansrådgivning",
    "Fitness": "Trening",
    "Food Delivery": "Matlevering",
    "Gardening": "Hagearbeid",
    "Graphic Design": "Grafisk design",
    "Hair Stylist": "Frisør",
    "Handyman": "Vaktmester",
    "Housecleaning": "Husvask",
    "Illustrating": "Illustrasjon",
    "Influencer": "Influencer",
    "Insurance Broker": "Forsikringsmegler",
    "Interior Design": "Interiørdesign",
    "IT Consulting": "IT-rådgivning",
    "Landscaping": "Landskapsarbeid",
    "Law Firm": "Advokatfirma",
    "Lawn Care": "Plenpleie",
    "Locksmith": "Låsesmed",
    "Makeup Artist": "Makeup-artist",
    "Marketing": "Markedsføring",
    "Massage Therapy": "Massasjeterapi",
    "Mechanic": "Mekaniker",
    "Mobile Repair": "Mobilreparasjon",
    "Music Instruction": "Musikkundervisning",
    "Online Entertainment": "Underholdning på nett",
    "Online Learning": "Læring på nett",
    "Online Store": "Nettbutikk",
    "Painting": "Maling",
    "Personal Training": "Personlig trening",
    "Pest Control": "Skadedyrkontroll",
    "Pet Sitting": "Dyrepass",
    "Photography": "Fotografering",
    "Physiotherapy": "Fysioterapi",
    "Pizza Restaurant": "Pizzarestaurant",
    "Plumbing": "Rørleggerarbeid",
    "Pool Cleaning": "Bassengrengjøring",
    "Pressure Washing": "Høytrykkspyling",
    "Project Management": "Prosjektledelse",
    "Property Management": "Eiendomsforvaltning",
    "Psychology": "Psykologi",
    "Public Relations": "PR",
    "Realestate": "Eiendom",
    "Recruiting": "Rekruttering",
    "Restaurant": "Restaurant",
    "Resume Writing": "CV-skriving",
    "Retail Store": "Butikk",
    "Security": "Sikkerhet",
    "Social Media Marketing": "Markedsføring på sosiale medier",
    "Software Development": "Programvareutvikling",
    "Solar Installation": "Solinstallasjon",
    "Sports Instruction": "Sportsundervisning",
    "Taxi": "Taxi",
    "Tourism": "Turisme",
    "Translation": "Oversettelse",
    "Travel Agency": "Reisebyrå",
    "Trucking": "Lastebilkjøring",
    "Tutoring": "Privatundervisning",
    "UI/UX Design": "UI/UX-design",
    "Video Editing": "Videoredigering",
    "Video Production": "Videoproduksjon",
    "Web Design": "Webdesign",
    "Web Development": "Webutvikling",
    "Wellness": "Velvære",
    "Writing": "Skriving",
    "Yoga Instruction": "Yoga-undervisning",
    "YouTuber": "YouTuber"
};

export default industryTranslations;