import {IconButton} from "@mui/material";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import {CustomTextField} from "../customComponents/CustomTextField";

const SearchField = ({searchQuery, handleSearchChange, label, marginRight}) => (
    <CustomTextField
        label={label}
        variant="outlined"
        size="small"
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
            endAdornment: (
                <IconButton position="end" aria-label="Search">
                    <SearchIcon/>
                </IconButton>
            ),
        }}
        InputLabelProps={{
            style: {fontSize: '1.2em'}, // Adjust the font size here as well if needed
        }}
        sx={{backgroundColor: '#F5F5F5', marginRight: marginRight}}
    />
);

export default SearchField;