import React, {Suspense} from 'react';
import './App.css';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AuthProvider} from "./helperFunctions/authentication/authContext";
import PrivateRoute from "./components/conditionals/privateRoute";
import Logout from "./components/logout/Logout";
import {SnackbarProvider} from "./helperFunctions/notification/SnackbarContext";
import Loader from "./components/customComponents/Loader";
import NettsidePage from "./pages/NettsidePage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentFailedPage from "./pages/PaymentFailedPage";
import TrialSuccessPage from "./pages/TrialSuccessPage";

// Lazy load the pages
const SignInSide = React.lazy(() => import("./pages/SignInSide"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const Trafikk = React.lazy(() => import("./pages/Traffic"));
const LessonGroupsPage = React.lazy(() => import("./pages/LessonGroupsPage"));
const LessonPage = React.lazy(() => import("./pages/LessonPage"));
const SettingsPage = React.lazy(() => import("./pages/SettingsPage"));
const HelpPage = React.lazy(() => import("./pages/HelpPage"));
const AdminPage = React.lazy(() => import("./pages/AdminPage"));
const PrivacyPage = React.lazy(() => import("./pages/PrivacyPage"));
const TermsPage = React.lazy(() => import("./pages/TermsPage"));
const PasswordResetRequest = React.lazy(() => import("./pages/PasswordResetRequest"));
const PasswordResetConfirm = React.lazy(() => import("./pages/PasswordResetConfirm"));

function App() {
    return (
        <Router>
            <AuthProvider>
                <GoogleOAuthProvider clientId="75516441443-7bda4pqgn22afk4h9bi451lnevnpje7l.apps.googleusercontent.com">
                    <SnackbarProvider>
                        <div className="App">
                            <Suspense fallback={<Loader size={100}/>}>
                                <Routes>
                                    <Route path="/login" element={<SignInSide/>}/>
                                    <Route path="/" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                                    <Route path="/trafikk" element={<PrivateRoute><Trafikk/></PrivateRoute>}/>
                                    <Route path="/register" element={<SignUp/>}/>
                                    <Route path="/logout" element={<Logout/>}/>
                                    <Route path="/guider" element={<PrivateRoute><LessonGroupsPage/></PrivateRoute>}/>
                                    <Route path="/guider/:groupId"
                                           element={<PrivateRoute><LessonPage/></PrivateRoute>}/>
                                    <Route path="/guider/:groupId/:lessonId"
                                           element={<PrivateRoute><LessonPage/></PrivateRoute>}/>
                                    <Route path="/settings" element={<PrivateRoute><SettingsPage/></PrivateRoute>}/>
                                    <Route path="/hjelp" element={<HelpPage/>}/>
                                    <Route path="/admin" element={<PrivateRoute><AdminPage/></PrivateRoute>}/>
                                    <Route path="/personvern" element={<PrivacyPage/>}/>
                                    <Route path="/vilkår" element={<TermsPage/>}/>
                                    <Route path="/nettside" element={<PrivateRoute><NettsidePage/></PrivateRoute>}/>
                                    <Route path="/reset" element={<PasswordResetRequest/>}/>
                                    <Route path="/reset-password/:uid/:token" element={<PasswordResetConfirm/>}/>
                                    <Route path="/suksess"
                                           element={<PrivateRoute><TrialSuccessPage/></PrivateRoute>}/>
                                    <Route path="/feilet"
                                           element={<PrivateRoute><PaymentFailedPage/></PrivateRoute>}/>
                                    <Route path="/betaling-suksess"
                                           element={<PrivateRoute><PaymentSuccessPage/></PrivateRoute>}/>
                                </Routes>
                            </Suspense>
                        </div>
                    </SnackbarProvider>
                </GoogleOAuthProvider>
            </AuthProvider>
        </Router>
    );
};

export default App;