import axiosInstance from "../authentication/axiosInstance";

const getProjectUid = async (projectId) => {
    try {
        // Use axiosInstance to make the get request
        const response = await axiosInstance.get(`/get_project_uid/${projectId}/`);

        // Check if the request was successful based on the status code
        if (response.status === 200) {
            // The response data is available in `response.data`
            return response.data.project_uid; // Return the project UID directly
        } else {
            // Handle any other status codes as an error
            console.error('Failed to get project UID:', response.data);
            throw new Error(response.data.error || 'Get Project UID Failed');
        }
    } catch (error) {
        // Log and rethrow the error for further handling
        console.error('Error getting project UID:', error);
        throw error;
    }
};

export default getProjectUid;