import axiosInstance from '../authentication/axiosInstance';

const changeSubdomain = async (projectId, domainId, newSubdomain) => {
    try {
        const response = await axiosInstance.put('/change-subdomain-name/', {
            project_id: projectId,
            domain_id: domainId,
            new_subdomain: newSubdomain
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};

export default changeSubdomain;
