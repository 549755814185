import {styled} from '@mui/material/styles';
import Card from '@mui/material/Card';
import {color} from "../../theme";

const sizeWidths = {
    xs: '268px',
    s: '300px',
    sm: '350px',
    m: '450px',
    l: '700px',
    xl: '900px'
};

// const sizeHeights = {
//     xs: '201px',
//     s: '225px',
//     sm: '263px',
//     m: '338px',
//     l: '525px',
//     xl: '675px'
// };

const CustomCard = styled(Card)(({theme, size}) => ({
    boxSizing: 'border-box',
    width: sizeWidths[size],
    background: color.white.white100,
    borderRadius: '8px',
    position: 'relative',
    border: `1px solid ${color.lightGrey.lightGrey25S2}`,
    padding: '16px',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    },
}));

export {CustomCard};
