import React from 'react';
import {Box, Card, CardActions, CardContent, Typography, useMediaQuery} from '@mui/material';
import {CustomButton} from '../customComponents/CustomButton';

const MyWebsiteCards = ({setActivePage, handleOpenAiBuilderModal, isCompact}) => {
    const isSmallScreen = useMediaQuery('(max-width:1440px)');
    const cardWidth = isCompact ? (isSmallScreen ? '450px' : '500px') : (isSmallScreen ? '550px' : '650px');
    const cardHeight = isCompact ? 'fit-content' : '85%';
    const cardBottomMargin = isCompact ? '0' : '2em';
    const templateSpanPadding = isSmallScreen ? '2em' : '0em';
    const isCompactJustifyContent = isCompact ? 'space-evenly' : 'flex-start';

    const malerNettsideVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/maler-nettside.webm"
    const aiByggerVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/ai-bygger.webm";

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: isCompactJustifyContent,
                alignItems: 'flex-start',
                height: 'fit-content',
                maxWidth: 'none',
                mb: '3em'
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    width: cardWidth,
                    padding: '2em',
                    height: cardHeight,
                    flexDirection: 'column',
                    mr: '3em',
                    transition: 'box-shadow 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                    },
                }}
            >
                <CardContent>
                    <Typography variant="h3" component="div" sx={{mb: 3, fontFamily: 'Fraunces'}}>
                        Start med en mal
                    </Typography>
                    <Typography variant="body"
                                sx={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: templateSpanPadding,
                                    fontWeight: '600',
                                }}>
                        Velg mellom 200+ ferdige nettsideutkast
                    </Typography>
                </CardContent>
                <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                    <Box
                        component="video"
                        sx={{width: '100%'}}
                        src={malerNettsideVideoUrl}
                        preload="metadata"
                        autoPlay
                        loop
                        muted
                    />
                    <CardActions sx={{mt: 'auto', justifyContent: 'center', mb: cardBottomMargin}}>
                        <CustomButton colors="sixth" variant="contained"
                                      size="large"
                                      sx={{width: '90%'}}
                                      onClick={() => setActivePage('Nettsidemaler')}>
                            Velg et utkast
                        </CustomButton>
                    </CardActions>
                </Box>
            </Card>
            <Card
                sx={{
                    display: 'flex',
                    width: cardWidth,
                    padding: '2em',
                    height: cardHeight,
                    flexDirection: 'column',
                    transition: 'box-shadow 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                    },
                }}
            >
                <CardContent>
                    <Typography variant="h3" component="div" sx={{mb: 3, fontFamily: 'Fraunces'}}>
                        Bygg med AI
                    </Typography>
                    <Typography variant="body"
                                sx={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: templateSpanPadding,
                                    fontWeight: '600',
                                }}>
                        La vår AI bygge et utkast til nettsiden din
                    </Typography>
                </CardContent>
                <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                    <Box
                        component="video"
                        sx={{width: '100%'}}
                        src={aiByggerVideoUrl}
                        preload="metadata"
                        autoPlay
                        loop
                        muted
                    />
                    <CardActions sx={{mt: 'auto', justifyContent: 'center', mb: cardBottomMargin}}>
                        <CustomButton
                            colors="quaternary"
                            onClick={handleOpenAiBuilderModal}
                            variant="contained"
                            size="large"
                            sx={{width: '90%'}}
                        >
                            Start med AI
                        </CustomButton>
                    </CardActions>
                </Box>
            </Card>
        </Box>
    );
};

export default MyWebsiteCards;
