import axiosInstance from "../authentication/axiosInstance";

const createSSOLink = async (projectUid, sendEmail) => {
    try {
        const response = await axiosInstance.post('/create-sso-link/', {
            project_uid: projectUid,
            send_email: sendEmail
        });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Failed to create SSO link:', response.data);
            throw new Error(response.data.error || 'SSO Link Creation Failed');
        }
    } catch (error) {
        console.error('Error creating SSO link:', error);
        throw error;
    }
};

export default createSSOLink;
