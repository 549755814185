import axiosInstance from "../authentication/axiosInstance";

export const fetchTemplates = async (categoryId = 3304262, page = 1, count = 200) => { //Default is ALL
    try {
        const response = await axiosInstance.get(`/templates/`, {
            params: {
                category: categoryId,
                page,
                count
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
        return null; // Return null or appropriate error handling
    }
};
