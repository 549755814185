import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {CustomButton} from '../customComponents/CustomButton';
import modalStyle from "../customComponents/modalStyle";

const LinkModal = ({ssoLink, handleClose}) => (
    <Box sx={modalStyle}>
        <IconButton
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 16,
                top: 16,
            }}
        >
            <CloseIcon fontSize="large"/>
        </IconButton>
        <Typography variant="h5" component="h2" sx={{textAlign: 'center', mt: '2em', mb: '1em'}}>
            Dine nettside er opprettet. Klikk på knappen under for å åpne nettsiden i redigeringsverktøyet.
        </Typography>
        <Box sx={{textAlign: 'center'}}>
            <Typography
                variant="body"
                sx={{
                    mt: 1,
                    mb: 2,
                    textAlign: 'center',
                    lineHeight: 1.6,
                    display: 'inline-block',
                }}
            >
                Du kan se en oversikt over din nettside i "Min nettside".
                <br/>
                Der kan du publisere din nettside, gå tilbake til editoren eller slette den.
            </Typography>
        </Box>
        <Box component="div" sx={{textAlign: 'center', mt: 3}}>
            <CustomButton onClick={() => window.open(ssoLink, '_blank')} colors="primary">
                Gå til nettside
            </CustomButton>
        </Box>
    </Box>
);

export default LinkModal;
