import axiosInstance from '../authentication/axiosInstance';

const addCustomDomain = async (projectId, customDomain) => {
    const response = await axiosInstance.post('/add-custom-domain/', {
        project_id: projectId,
        custom_domain: customDomain,
    });
    return response.data;
};

export default addCustomDomain;
