import React, {useEffect} from 'react';
import {Box, Card, CardContent, Container, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Navbar from '../components/navbar/Navbar';
import {color} from '../theme';
import {CustomButton} from "../components/customComponents/CustomButton";
import {useNavigate} from "react-router-dom";
import {fetchSubscriptionStatus} from "../helperFunctions/stripe/fetchSubscriptionStatus";

const TrialSuccessPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const getSubscriptionStatus = async () => {
            const subscriptionData = await fetchSubscriptionStatus();
            localStorage.setItem('subscription_status', subscriptionData.status);
            localStorage.setItem('subscription_type', subscriptionData.subscription_type);
            let accountType = 'Gratis-konto';
            if (subscriptionData.status === 'trialing') {
                accountType = 'Trial-konto';
            } else if (subscriptionData.status === 'active') {
                accountType = 'Abonnert';
            }
            localStorage.setItem('account_type', accountType);
        }
        getSubscriptionStatus();
    }, []);

    return (
        <Container sx={{display: 'flex', flexDirection: 'row', height: '100vh'}} maxWidth="lg">
            <Navbar/>
            <Box sx={{
                marginLeft: '208px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}>
                <Card sx={{minWidth: 275, padding: '2em'}}>
                    <CardContent sx={{textAlign: 'center'}}>
                        <CheckCircleIcon sx={{fontSize: 80, color: color.green.baseGreen100, marginBottom: '0.2em'}}/>
                        <Typography variant="h4" component="div" gutterBottom>
                            Trial startet
                        </Typography>
                        <Typography variant="body" sx={{marginTop: '0.5em'}}>
                            Din trial-registering er vellykket, og du er nå abonnert.
                        </Typography>
                        <Typography variant="body" display="block" sx={{marginTop: '0.5em'}}>
                            Du har opp til 30 dager gratis prøveperiode og vil ikke bli belastet før prøveperioden er
                            over.
                        </Typography>
                        <Typography variant="body" display="block" sx={{marginTop: '0.5em'}}>
                            Du kan oppdatere betalingsinformasjon og se hvor lenge du har igjen av prøveperioden i
                            <a href="/settings" style={{textDecoration: "none"}}> Innstillinger</a>.
                        </Typography>
                        <Typography variant="body" display="block" sx={{marginTop: '0.5em'}}>
                            Du kan nå <a href="/nettside" style={{textDecoration: "none"}}> bygge din egen nettside</a>,
                            og publisere (lansere live) nettsidene dine.
                        </Typography>
                        <Typography variant="body" display="block" sx={{marginTop: '0.5em'}}>
                            Hvis du har bestilt <strong>full-pakke</strong> abonnementet så kan du nå se våre <a
                            href="/guider" style={{textDecoration: "none"}}> kursvideoer om digital markedsføring</a>
                        </Typography>
                        <Box sx={{marginTop: '2em'}}>
                            <CustomButton variant="filled" onClick={() => navigate("/")}>Gå tilbake til
                                dashboard</CustomButton>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default TrialSuccessPage;
