import React from "react";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {CustomButton} from "../customComponents/CustomButton";
import {CustomTextField} from "../customComponents/CustomTextField";
import CustomTooltip from "../customComponents/CustomTooltip";
import modalStyle from "../customComponents/modalStyle";

const WebsiteForm = ({
                         formData,
                         handleChange,
                         handleSubmit,
                         handleClose,
                     }) => (
    <Box sx={modalStyle}>
        <IconButton
            onClick={handleClose}
            sx={{
                position: "absolute",
                right: 16,
                top: 16,
            }}
        >
            <CloseIcon fontSize="large"/>
        </IconButton>
        <Typography variant="h4" component="h2" sx={{textAlign: "center", mt: "1em"}}>
            Hva skal nettsiden din hete?
        </Typography>
        <Box
            component="form"
            sx={{"& .MuiTextField-root": {m: 1, width: "100%"}}}
            noValidate
            autoComplete="off"
        >
            <Box
                component="div"
                sx={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", mt: 2, mb: 0.5}}
            >
                <CustomTooltip title="Navnet til nettsiden (f.eks Groweb)">
                    <CustomTextField
                        fullWidth
                        margin="normal"
                        label="Navn"
                        variant="outlined"
                        name="name"
                        size="medium"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </CustomTooltip>
            </Box>
            <Box component="div" sx={{textAlign: "center", mt: 3}}>
                <CustomButton onClick={handleSubmit} variant="contained" colors="sixth" sx={{p: "16px"}}>
                    Lagre og fortsett
                </CustomButton>
            </Box>
        </Box>
    </Box>
);

export default WebsiteForm;
