import React, {useEffect, useState} from 'react';
import {Alert, Container} from '@mui/material';
import axiosInstance from "../../helperFunctions/authentication/axiosInstance";
import SsoLinkDisplay from "./SsoLinkDisplay";
import IngenNettsiderError from "./IngenNettsiderError";
import createSSOLink from "../../helperFunctions/nettside/createSsoLink";
import getProjectUid from "../../helperFunctions/nettside/getProjectUid";
import {useNavigate} from "react-router-dom";
import MyWebsiteCards from "./MyWebsiteCards";
import useMediaQuery from "@mui/material/useMediaQuery";
import {CustomButton} from "../customComponents/CustomButton";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import {AddCircleOutlined} from "@mui/icons-material";
import createEkstraNettsideCheckoutSession from "../../helperFunctions/stripe/createEkstraNettsideCheckoutSession";
import CustomTooltip from "../customComponents/CustomTooltip";
import SkeletonMyWebsiteCard from "../skeleton/SkeletonMyWebsiteCard";

const MyWebsitesSubpage = ({
                               subscriptionStatus,
                               trialDays,
                               isAdmin,
                               setActivePage,
                               handleOpenAiBuilderModal,
                               websiteQuota
                           }) => {
    const [loading, setLoading] = useState(true);
    const [ssoLinks, setSsoLinks] = useState([]);
    const [projectNames, setProjectNames] = useState({});
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:1440px)');
    const containerWidth = isSmallScreen ? '90%' : '90%';

    useEffect(() => {
        if (!['trialing', 'active'].includes(subscriptionStatus) && !isAdmin) {
            setLoading(false);
            return;
        }

        const fetchUserProfileAndSSOLinks = async () => {
            setLoading(true);
            try {
                const profileResponse = await axiosInstance.get('sync-user-projects/');
                const {brizy_user_id, project_ids, project_names} = profileResponse.data;
                localStorage.setItem('workspace_id', profileResponse.data.workspace_id);

                if (brizy_user_id && project_ids.length > 0) {
                    const links = await Promise.all(project_ids.map(async (project_id) => {
                        const projectUid = await getProjectUid(project_id);
                        const ssoResponse = await createSSOLink(projectUid);
                        return {link: ssoResponse.sso_link, id: project_id};
                    }));
                    localStorage.setItem('brizy_user_id', brizy_user_id);
                    setSsoLinks(links);
                    setProjectNames(project_names);
                }
            } catch (err) {
                const errorMsg = err.response && err.response.data && err.response.data.error
                    ? err.response.data.error
                    : 'Klarte ikke å hente data: ' + err.message;
                setError(errorMsg);
            }
            setLoading(false);
        };

        fetchUserProfileAndSSOLinks();
    }, [subscriptionStatus, isAdmin]);

    const handleDeleteSuccess = (deletedProjectId) => {
        setSsoLinks(prevLinks => prevLinks.filter(link => link.id !== deletedProjectId));
    };


    return (
        <Container
            disableGutters
            maxWidth={false}
            sx={{marginTop: 8, width: containerWidth, marginLeft: isSmallScreen ? '15%' : '12%'}}
        >
            {error ? (
                <IngenNettsiderError error={error}/>
            ) : ssoLinks.length > 0 ? (
                <>
                    {ssoLinks.map((linkObj, index) => (
                        <SsoLinkDisplay
                            key={index}
                            ssoLink={linkObj.link}
                            siteNumber={projectNames[linkObj.id] || `Website #${index + 1}`}
                            projectId={linkObj.id}
                            onDeleteSuccess={handleDeleteSuccess}
                        />
                    ))}
                    {websiteQuota === ssoLinks.length ? (
                        <Box sx={{marginLeft: 'auto', marginTop: '8em'}}>
                            <Typography variant="h4" sx={{marginTop: 2, mb: '1em'}}>
                                Trenger du flere nettsider?
                            </Typography>
                            <CustomTooltip
                                title="Klikk her for å lage flere nettsider."
                                arrow>
                                <CustomButton
                                    colors="quaternary"
                                    size="large"
                                    endIcon={<AddCircleOutlined/>}
                                    onClick={createEkstraNettsideCheckoutSession}
                                >
                                    Bestill en ny nettside
                                </CustomButton>
                            </CustomTooltip>
                        </Box>
                    ) : (
                        <Box>
                            <Alert severity="success" sx={{marginTop: 2, mb: '2em'}}>
                                <Typography variant="body1" sx={{textAlign: 'left'}}>
                                    Du har kjøpt <b>{websiteQuota - 1}</b> ekstra nettside. Du kan lage og publisere en
                                    ny nettside ved å
                                    velge en
                                    av
                                    fremgangsmåtene under.
                                </Typography>
                            </Alert>
                            {loading ? (
                                <Box display="flex" flexDirection="row" flexWrap="wrap" gap='12px' alignItems="center"
                                     justifyContent="space-evenly">
                                    <SkeletonMyWebsiteCard isCompact={true}/>
                                    <SkeletonMyWebsiteCard isCompact={true}/>
                                </Box>
                            ) : (
                                <MyWebsiteCards
                                    handleOpenAiBuilderModal={handleOpenAiBuilderModal}
                                    setActivePage={setActivePage}
                                    isCompact={true}
                                />
                            )}
                        </Box>
                    )}
                </>
            ) : loading ? (
                <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center"
                     justifyContent="flex-start">
                    <SkeletonMyWebsiteCard/>
                    <SkeletonMyWebsiteCard/>
                </Box>
            ) : (
                <MyWebsiteCards handleOpenAiBuilderModal={handleOpenAiBuilderModal}
                                setActivePage={setActivePage}/>
            )
            }
        </Container>
    );
};

export default MyWebsitesSubpage;
