const templatePreviewUrls = {
    "Snow Removal": "https://snowremoval.groweb.cloud/",
    "PC Repair": "https://computerfix.groweb.cloud/",
    "Landscaping": "https://landscaping1.groweb.cloud/",
    "Handymen": "https://localhandymen.groweb.cloud/",
    "Driving": "https://driverforhire.groweb.cloud/",
    "Dog Walking": "https://dogwalking1.groweb.cloud/",
    "Daycare": "https://daycare1.groweb.cloud/",
    "Carpentry": "https://carpentry.groweb.cloud/",
    "BodyCar": "https://bodycar.groweb.cloud/",
    "Babysitting": "https://babysitting.groweb.cloud/",
    "Appliance": "https://appliance2.groweb.cloud/",
    "Animal Shelter": "https://animalshelternew.groweb.cloud/",
    "Veterinary": "https://veterinary.groweb.cloud/",
    "Workshop": "https://multiworkshop.groweb.cloud/",
    "Parley": "https://podcastlanding.groweb.cloud/",
    "Halbor": "https://halbor.groweb.cloud/",
    "Gym Trainer": "https://gymtrainer.groweb.cloud/",
    "Tavern": "https://restauranttemp.groweb.cloud/",
    "Nestnet": "https://subscriptionsales.groweb.cloud/",
    "Gprig": "https://saaslandingpages.groweb.cloud/",
    "Portfolio": "https://portfoliopage.groweb.cloud/",
    "Petgo": "https://petstore3.groweb.cloud/",
    "Cleanoz": "https://cleanorz.groweb.cloud/",
    "Music Tutor": "https://musictutor.groweb.cloud/",
    "Podkast": "https://lounchpodcast.groweb.cloud/",
    "Nursery": "https://nursery.groweb.cloud/",
    "Dance Tutor": "https://dancetutor.groweb.cloud/",
    "Dojo Sensei": "https://dojosensei.groweb.cloud/",
    "Linoor": "https://linoor.groweb.cloud/",
    "Growth": "https://growth2.groweb.cloud/",
    "Yoga 2": "https://yoganew.groweb.cloud/",
    "Trefon": "https://trefon.groweb.cloud/",
    "Travely": "https://travely.groweb.cloud/",
    "Telemedicine": "https://telemed.groweb.cloud/",
    "Tattoo shop": "https://tattooshop.groweb.cloud/",
    "Sushi": "https://sushi.groweb.cloud/",
    "Starter": "https://starter.groweb.cloud/",
    "Skiing": "https://skiing.groweb.cloud/",
    "Sintix": "https://sintix.groweb.cloud/",
    "Scooter Rental": "https://scooterrental.groweb.cloud/",
    "Safari": "https://safari.groweb.cloud/",
    "Restaurant": "https://restaurant3.groweb.cloud/",
    "Real Talk": "https://realtalk.groweb.cloud/",
    "Rana Foxa": "https://ranafoxa.groweb.cloud/",
    "Podact": "https://podact.groweb.cloud/",
    "Pizza": "https://pizzalp.groweb.cloud/",
    "Parfume": "https://parfume.groweb.cloud/",
    "Orange": "https://orangephotography.groweb.cloud/",
    "Oktane Crypto": "https://oktan3.groweb.cloud/",
    "Number 5": "https://number5.groweb.cloud/",
    "Mimo Design": "https://mimodesign.groweb.cloud/",
    "Mechanics": "https://mechaniclp.groweb.cloud/",
    "IT Service": "https://itrepair.groweb.cloud/",
    "Insurace": "https://insurace.groweb.cloud/",
    "Horses": "https://horses.groweb.cloud/",
    "Home Shop": "https://homeshop.groweb.cloud/",
    "Healthy": "https://healthcaring.groweb.cloud/",
    "Gym": "https://gym2.groweb.cloud/",
    "Fashion Models": "https://modelagency.groweb.cloud/",
    "Electric": "https://electriclp.groweb.cloud/",
    "Eladio": "https://eladio.groweb.cloud/",
    "E-Cycle": "https://ebikes.groweb.cloud/",
    "Dietitian": "https://dieti.groweb.cloud/",
    "Dance School": "https://danceschool2.groweb.cloud/",
    "Crypto Things": "https://cryptothings.groweb.cloud/",
    "Corpio": "https://corpio.groweb.cloud/",
    "Car Wash": "https://carwashnew.groweb.cloud/",
    "Car Parts": "https://carparts.groweb.cloud/",
    "Brandio": "https://brandio.groweb.cloud/",
    "Birthday": "https://birthdayparty.groweb.cloud/",
    "Wedding": "https://weddingph.groweb.cloud/",
    "Co-Space": "https://cospace.groweb.cloud//",
    "Drone X": "https://dronex.groweb.cloud/",
    "Smart Learning": "https://smartlearning.groweb.cloud/",
    "Surf School": "https://surfing.groweb.cloud/",
    "Football United": "https://footballfc.groweb.cloud/",
    "Memento App": "https://memento.groweb.cloud/",
    "Craft Wise": "https://craftwise.groweb.cloud/",
    "Coin Finance": "https://coin.groweb.cloud/",
    "Design Loft": "https://loftdesign.groweb.cloud/",
    "Empire SaaS": "https://empire.groweb.cloud/",
    "Camera App": "https://cameraapp.groweb.cloud/",
    "Ranking SEO": "https://rankking.groweb.cloud/",
    "Gourmet Food": "https://gourmet.groweb.cloud/",
    "Molino Bake": "https://molino.groweb.cloud/",
    "Architect": "https://architekt.groweb.cloud/",
    "Fix It": "https://fixit.groweb.cloud/",
    "Wine Vine": "https://winevine.groweb.cloud/",
    "Moon Band": "https://moon.groweb.cloud/",
    "Workspaces": "https://workspace.groweb.cloud/",
    "Pet Vet Care": "https://petits.groweb.cloud/",
    "Pearl Dental": "https://pearldent.groweb.cloud/",
    "Natural Beauty Spa": "https://naturalbeauty.groweb.cloud/",
    "IT Group": "https://itgroup.groweb.cloud/",
    "Kids": "https://kids2.groweb.cloud/",
    "Private School": "https://school2.groweb.cloud/",
    "Carpenter": "https://carpenter.groweb.cloud/",
    "Manicure": "https://manicure1.groweb.cloud/",
    "Hairdresser": "https://hairdresser.groweb.cloud/",
    "Minimal": "https://minimal.groweb.cloud/",
    "Wedding Photo": "https://weddingph2.groweb.cloud/",
    "Zoo": "https://zoo.groweb.cloud/",
    "Interior Design": "https://interior.groweb.cloud/",
    "Politics 2": "https://politics2.groweb.cloud/",
    "Medical Center": "https://medicallp.groweb.cloud/",
    "Call Center": "https://callcenter.groweb.cloud/",
    "Consultant": "https://consultant.groweb.cloud/",
    "CryptoBold": "https://cryptobold.groweb.cloud/",
    "Party New": "https://partynew.groweb.cloud/",
    "Spa Center": "https://spacenter2.groweb.cloud/",
    "Finance": "https://financesite.groweb.cloud/",
    "Party 1": "https://partysite.groweb.cloud/",
    "Tailor 2": "https://tailor2.groweb.cloud/",
    "Music Site": "https://musicsite.groweb.cloud/",
    "Catering": "https://cateringlp.groweb.cloud/",
    "Resto": "https://resto.groweb.cloud/",
    "Laboratory": "https://lab2.groweb.cloud/",
    "Garden": "https://gardensite.groweb.cloud/",
    "Swimming Pool": "https://swimmingpool.groweb.cloud/",
    "Architect 2": "https://architect2.groweb.cloud/",
    "Taxi": "https://taxilp.groweb.cloud/",
    "Payss": "https://payss.groweb.cloud/",
    "Adventure": "https://adventures.groweb.cloud/",
    "The Farm": "https://thefarm.groweb.cloud/",
    "Festival": "https://festivalnew.groweb.cloud/",
    "Photography Course": "https://course.groweb.cloud/",
    "Meals on Wheels": "https://mealonwheels.groweb.cloud/",
    "Spirit": "https://spirit.groweb.cloud/",
    "SEO Agency": "https://seoagenc.groweb.cloud/",
    "Codesk": "https://codesk.groweb.cloud/",
    "Lodiz": "https://lodiz.groweb.cloud/",
    "Oapee": "https://oapee.groweb.cloud/",
    "Booky": "https://coverbook.groweb.cloud/",
    "Ditox": "https://detoxplan.groweb.cloud/",
    "Flooring": "https://flooringlp.groweb.cloud/",
    "Copyrits": "https://copyrits.groweb.cloud/",
    "Marketr": "https://marketr.groweb.cloud/",
    "Solting": "https://solting.groweb.cloud/",
    "Vopril": "https://vopril.groweb.cloud/",
    "Soofer": "https://soofer.groweb.cloud/",
    "Trainer": "https://trainerlp.groweb.cloud/",
    "Fitness": "https://fitnesslp.groweb.cloud/",
    "Houziz": "https://houziz.groweb.cloud/",
    "Webno": "https://webnodigital.groweb.cloud/",
    "Bizgo": "https://bizgo2.groweb.cloud/",
    "Zarbin": "https://zarbin.groweb.cloud/",
    "Okloos": "https://sellglasses.groweb.cloud/",
    "Timon": "https://timon.groweb.cloud/",
    "Course": "https://sellcourse.groweb.cloud/",
    "eBook": "https://ebooklp.groweb.cloud/",
    "Riston": "https://restaurantlp.groweb.cloud/",
    "Medico": "https://doctorlp.groweb.cloud/",
    "Motion": "https://motionlp.groweb.cloud/",
    "Guide": "https://guidelp.groweb.cloud/",
    "Dieting": "https://dieting.groweb.cloud/",
    "Atorno": "https://atornolp.groweb.cloud/",
    "Instom": "https://instagramlp.groweb.cloud/",
    "Beauty": "https://sellbeauty.groweb.cloud/",
    "Black Friday": "https://blackfridaylp.groweb.cloud/",
    "Ekstra": "https://ekstra.groweb.cloud/",
    "Appside": "https://appside.groweb.cloud/",
    "Moves": "https://moves.groweb.cloud/",
    "Hitched": "https://hitched.groweb.cloud/",
    "Summit": "https://summit.groweb.cloud/",
    "Digital Raw": "https://digitalraw1.groweb.cloud/",
    "Startapp": "https://startapp.groweb.cloud/",
    "Unicorn": "https://unicorn1.groweb.cloud/",
    "Supreme": "https://supreme1.groweb.cloud/",
    "Swipe": "https://swipe1.groweb.cloud/",
    "Resume": "https://resumenew.groweb.cloud/",
    "Parlor": "https://parlor.groweb.cloud/",
    "Formstyle": "https://former.groweb.cloud/",
    "Webinar": "https://webinar.groweb.cloud/",
    "In Shape": "https://inshape.groweb.cloud/",
    "Keynote": "https://keynote.groweb.cloud/",
    "Kid Quest": "https://kidquest.groweb.cloud/",
    "Philanthropy": "https://philanthropy.groweb.cloud/",
    "Quantum": "https://quantum1.groweb.cloud/",
    "Reel Story": "https://reelstory.groweb.cloud/",
    "Skypoint": "https://skypoint.groweb.cloud/",
    "Yoga": "https://yogacontact.groweb.cloud/",
    "Beach Resort": "https://beachresort1.groweb.cloud/",
    "Car Clinic": "https://carclinic.groweb.cloud/",
    "College": "https://college1.groweb.cloud/",
    "Creed": "https://creed.groweb.cloud/",
    "Base Ground": "https://baseground.groweb.cloud/",
    "Alpine Lodge": "https://alpinelodge.groweb.cloud/",
    "Advisors": "https://advisors.groweb.cloud/",
    "Lavish": "https://lavish.groweb.cloud/",
    "Welness": "https://wellness.groweb.cloud/",
    "Refix": "https://refix1.groweb.cloud/",
    "Kaufman": "https://kaufman1.groweb.cloud/",
    "The Core": "https://thecore.groweb.cloud/",
    "Pathfinder": "https://pathfinder.groweb.cloud/",
    "Kids Play": "https://kidsplay.groweb.cloud/",
    "Homefeast": "https://homefeast.groweb.cloud/",
    "Practice": "https://thepractice.groweb.cloud/",
    "Digital Work": "https://digitalagency.groweb.cloud/",
    "Cribs": "https://cribs.groweb.cloud/",
    "Spotless": "https://spotless.groweb.cloud/",
    "Agens": "https://agens.groweb.cloud/",
    "Hope": "https://hope.groweb.cloud/",
    "Flavour": "https://flavour.groweb.cloud/",
    "Avalon": "https://avalon.groweb.cloud/",
    "Agrikol": "https://agrikol.groweb.cloud/",
    "Azino": "https://azino.groweb.cloud/",
    "Bebio": "https://bebio.groweb.cloud/",
    "Yachter": "https://yachter.groweb.cloud/",
    "Uplyft": "https://uplyft.groweb.cloud/",
    "Krowd": "https://krowd.groweb.cloud/",
    "Politiko": "https://politiko1.groweb.cloud/",
    "Brick & Mortar": "https://brickio.groweb.cloud/",
}

export default templatePreviewUrls