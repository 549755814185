// helperFunctions/subscriptionHelper.js
import axiosInstance from '../authentication/axiosInstance';

export const fetchSubscriptionStatus = async () => {
    try {
        const response = await axiosInstance.get('/get-subscription-status/', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        localStorage.setItem('hasHadTrial', response.data.has_had_trial)
        return {
            status: response.data.subscription_status,
            subscription_type: response.data.subscription_type,
            trialDays: response.data.trial_days_remaining,
            isAdmin: response.data.isAdmin,
            websiteQuota: response.data.website_quota
        };
    } catch (error) {
        console.error('Error fetching subscription status', error);
        return null;
    }
};
